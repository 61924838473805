function buildTree(orgNodes) {
  const adminGroup = []
  const userGroup = []

  // 관리자/유저 분리
  orgNodes.forEach(item => {
    if (['godfather', 'admin'].includes(item.role)) {
      // 관리자 그룹에 추가
      adminGroup.push(item)
    } else {
      // 유저 그룹에 추가
      userGroup.push(item)
    }
  })

  function adminBuildTree(adminNodes) {
    if (!adminNodes.length) return []

    const map = new Map()

    adminNodes.forEach(item => {
      // 역할 카테고리 초기화
      if (!map.has(item.role)) {
        map.set(item.role, [])
      }
      map.get(item.role).push(item)
    })

    // 사이트별로 관리자를 정리하는 함수
    function adminsBySite(data) {
      if (!data) return []

      // admin이 2개이상
      if (data.length > 1) {
        const siteMap = []
        data.forEach(item => {
          const authSites = Array.isArray(item.authSite) ? item.authSite : [item.authSite]

          authSites.forEach(site => {
            // 해당 사이트가 이미 siteMap에 있는지 확인
            const hasSite = siteMap.find(s => s.site === site)

            // 사이트가 없으면 새로운 객체를 추가
            if (!hasSite) {
              siteMap.push({
                site,
                role: item.role,
                nickname: site,
                authSite: site,
                admins: [],
              })
            }

            // 해당 사이트의 관리자 목록에 현재 관리자 추가
            const currentSite = siteMap.find(s => s.site === site)
            currentSite.admins.push(item) // 관리자 추가
          })
        })
        return siteMap
      }
      // admin이 1개
      return data
    }

    function buildTreeByRole() {
      const tree = []

      // 최상위 노드가 godfather 인경우
      if (map.has('godfather')) {
        const godfathers = map.get('godfather')
        const admins = adminsBySite(map.get('admin'))
        const authSite = [...new Set(godfathers.flatMap(godfather => godfather.authSite))]
        const count = godfathers.length

        // 최상위 노드가 godfather가 2개이상
        if (count > 1) {
          // console.log(`Start building the root node for multiple 'godfathers'`);
          const newNode = {
            site: 'ADMIN',
            role: 'godfather',
            nickname: 'godfather',
            godfathers,
            authSite,
            ...(admins && admins.length > 0 && { children: admins.filter(item => authSite.includes(item.site)) }),
          }
          tree.push(newNode)
        }

        // 최상위 노드가 godfather가 1개인 경우
        if (count === 1) {
          // console.log(`Start building the root node for a single 'godfather'`);
          const [newNode] = map.get('godfather')
          newNode.children = admins.filter(item => {
            const sites = Array.isArray(item.authSite) ? item.authSite : [item.authSite]
            return sites.some(site => authSite.includes(site))
          })
          tree.push(newNode)
        }
      }

      // 최상위 노드가 admin 인경우
      if (!map.has('godfather') && map.has('admin')) {
        tree.push(...adminsBySite(map.get('admin')))
      }
      // console.log(JSON.stringify(tree))

      return tree
    }
    return buildTreeByRole()
  }

  function userBuildTree(userNodes, startLevel = 0) {
    // const startLevel = 0
    const map = {}
    const tree = []

    // 각 노드를 맵에 추가하고 추가 속성 초기화
    userNodes.forEach(item => {
      map[item.nickname] = { ...item, level: startLevel, totalCount: { userCount: 0, branchCount: 0 } }
    })

    // 부모-자식 관계 설정
    userNodes.forEach(item => {
      if (item.recommendNickname && map[item.recommendNickname]) {
        // children 속성이 없으면 추가
        if (!map[item.recommendNickname].children) {
          map[item.recommendNickname].children = []
        }

        // 자식 노드 추가
        const childNode = map[item.nickname]
        childNode.level = map[item.recommendNickname].level + 1 // 레벨 증가

        // childNode 집계
        if (childNode.role === 'user') {
          map[item.recommendNickname].totalCount.userCount += 1
        }
        if (childNode.role === 'branch') {
          map[item.recommendNickname].totalCount.branchCount += 1
        }
        map[item.recommendNickname].children.push(childNode)
      } else {
        // 최상위 노드일 경우
        tree.push(map[item.nickname])
      }
    })

    return tree
  }

  function concatTree(adminTree, userTree) {
    // adminTree가 빈 배열인 경우 userTree 반환
    if (adminTree.length === 0) {
      return userTree
    }

    // 최하위 노드에 userTree를 추가하는 함수
    function fncConcatNodeBySite(parentNode, childNode) {
      const node = { ...parentNode }
      const authSites = Array.isArray(node.authSite) ? node.authSite : [node.authSite]

      const children = node.children ? [...node.children] : []

      authSites.forEach(site => {
        childNode.forEach(item => {
          if (item.recommendNickname === site) {
            children.push(item)
          }
        })
      })

      return {
        ...node,
        children,
        totalCount: {
          userCount: children.filter(i => i.role === 'user').length,
          branchCount: children.filter(i => i.role === 'branch').length,
        },
      }
    }

    // 최하위 노드를 찾아서 userTree를 추가하는 함수
    function fncFindLowestLevelNode(node) {
      const { children } = node

      if (children && children.length > 0) {
        // 자식 노드가 있는 경우 재귀적으로 탐색
        return {
          ...node,
          children: children.map(child => fncFindLowestLevelNode(child)),
        }
      }
      // 자식 노드가 없으면 최하위 노드로 처리
      return fncConcatNodeBySite(node, userTree)
    }

    // 최하위 노드를 찾아서 userTree를 추가하는 작업 수행
    const updatedAdminTree = adminTree.map(node => fncFindLowestLevelNode(node))

    return updatedAdminTree
  }

  function setTreeLevel(node, currentLevel = 0) {
    // 새로운 객체를 만들어 level을 업데이트
    const newNode = { ...node, level: currentLevel }
    // 하위 노드(children)도 재귀적으로 업데이트
    if (newNode.children && newNode.children.length > 0) {
      newNode.children = newNode.children.map(child => setTreeLevel(child, currentLevel + 1))
    }
    return newNode
  }

  const adminTree = adminBuildTree(adminGroup)
  const userTree = userBuildTree(userGroup)
  const resultTree = concatTree(adminTree, userTree)
  const leveledData = resultTree.map(node => setTreeLevel(node))
  return leveledData
}

export default buildTree
