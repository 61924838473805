/* User */
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USERS = 'FETCH_USERS'

/* User(신) */
export const FETCH_USER_SINGLE = 'FETCH_USER_SINGLE'
export const FETCH_USER_MULTIPLE = 'FETCH_USER_MULTIPLE'
export const FETCH_USER_LIST_MULTIPLE = 'FETCH_USER_LIST_MULTIPLE'

/* UserBetHistory */
export const FETCH_USER_BET_HISTORY = 'FETCH_USER_BET_HISTORY'

/* UserUpdatgeLogs */
export const FETCH_USER_UPDATE_LOGS = 'FETCH_USER_UPDATE_LOGS'

export const FETCH_USER_RECOMMEND_LIST = 'FETCH_USER_RECOMMEND_LIST'
